<template>
    <div>
      <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
      <classesModal
        :modal.sync="modal"
        :ClassItems="ClassItems"
        :edit="edit"
        :title="titleModal"
        @submited="handleSubmit"
        @close="closeModal"
      />
        <CCardBody>
          <CRow>
            <CCol sm="12" class="d-flex justify-content-end">
              <CButton
                color="add"
                class="d-flex align-items-center"
                v-c-tooltip="{
                  content: $t('label.class'),
                  placement: 'top-end'
                }"
                @click="toggleAdd"
              >
                <CIcon name="cil-playlist-add"/><span class="ml-1">{{$t('label.nuevo')}}</span>
              </CButton>
            </CCol>
            <CCol sm="12">
              <dataTableExtended
                class="align-center-row-datatable"
                :items="formatedItems"
                :fields="fields"
                column-filter
                :noItemsView="tableText.noItemsViewText"
                :table-filter="tableText.tableFilterText"
                :items-per-page-select="tableText.itemsPerPageText"
                :items-per-page="tableText.itemsPerPage"
                hover
                small
                sorter
                pagination
              >
                <template #Status="{ item }">
                  <td class="text-center align-middle">
                    <CBadge :color="getBadge(item.Status)">
                      {{$t('label.'+item.Status)}}
                    </CBadge>
                  </td>
                </template>
                <template #Detalle="{ item }">
                  <td class="text-center">
                    <CButton
                      color="edit"
                      size="sm"
                      class="mr-1"
                      v-c-tooltip="{
                        content: $t('label.edit')+' '+$t('label.class'),
                        placement: 'top-end'
                      }"
                      @click="toggleEdit(item)"
                    >
                    <CIcon name="pencil" />
                  </CButton>
                  </td>
                </template>
              </dataTableExtended>
            </CCol>
          </CRow>
      </CCardBody>
    </div>
  </template>
    
  <script>
  import { DateFormater } from "@/_helpers/funciones";
  import General from "@/_mixins/general";
  import classesModal from "./modals/modal-classes";
  import { mapState } from 'vuex';
  
  //DATA
  function data() {
    return {
      items: [],
      Loading:  false,
      modal: false,
      AreaId: '',
      ClassItems: {},
      edit: false,
      titleModal: '',
    };
  }
  
  //METHOD
  function getListTpCargo() {
    this.Loading = true;
    this.$http
      .get('TpCargoClass-list')
      .then(response => {
        let listado = response.data.data;
        if (listado && listado.length != 0){
          this.items = listado;
        }
      })        
      .finally(() => {
        this.Loading = false;
      });
  }
  
  function toggleAdd() {
    this.modal = true;
    this.edit = false;
    this.titleModal = this.$t('label.nueva')+' '+this.$t('label.class');
  }
  function toggleEdit(item) {
    this.ClassItems = item;
    this.edit = true;
    this.modal = true;
    let title = item.TpCargoClassCode;
    this.titleModal= this.$t('label.edit')+' '+this.$t('label.class')+': '+title;
  }
  function closeModal(){
    this.modal = false;
    this.edit = false;
    this.titleModal = '';
  }
  function handleSubmit() {
    this.getListTpCargo();
  }

  function tabIndex(newValue,OldValue){
    if(newValue === 10){
        this.getListTpCargo();
    }
  }
  
  // COMPUTED
  function fields() {
    return [
      {
        key: "Nro",
        label: "#",
        _style: "width:1%;",
        filter: false,
      },
      { key: "TpCargoClassDsEs", label: this.$t("label.class")+' '+('(ES)'),_styles: "width: 30%;",_classes:'text-center',},
      { key: "TpCargoClassDsEn", label: this.$t("label.class")+' '+('(EN)'),_styles: "width: 30%;",_classes:'text-center',},
      { key: "TpCargoClassCode", label: this.$t("label.code"),_styles: "width: 15%;",_classes:'text-center',},
      { key: "Usuario", label: this.$t("label.user"), _styles: "width: 10%;",_classes: "text-center" },
      { key: "FormatedDate", label: this.$t("label.date"), _styles: "width: 10%;",_classes: "text-center" },
      { key: "Status", label: this.$t("label.status"),_styles: "width: 10%;", _classes: "text-center" },
      {
        key: "Detalle",
        label: "",
        sorter: false,
        filter: false,
        _styles: "min-width: 45px;",
      },
    ];
  }
  function formatedItems() {
    return this.items.map((items) =>
      Object.assign({}, items, {  
        Nro: items.Nro,
        TpCargoClassDsEs: items.TpCargoClassDsEs ?? "N/A",
        TpCargoClassDsEn: items.TpCargoClassDsEn ?? "N/A",
        Usuario: items.TransaLogin ?? "N/A",
        Status: items.Status ?? '',
        FormatedDate: items.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(items.TransaRegDate) : 'N/A',
        _classes: items.Status === "INACTIVO" ? "table-danger" : "",
      })
    );
  }
  
  export default {
    name: 'index-classes',
    mixins: [General],
    props: {Tab: Number},
    data,
    components: {
      classesModal
    },
    methods: {
      getListTpCargo,
      toggleAdd,
      toggleEdit,
      handleSubmit,
      closeModal,
    },
    computed: {
      fields,
      formatedItems,
      ...mapState({
            tabIndex: state => state.contenedores.tabIndex,
        })
    },
    watch:{
      tabIndex,
    }
  };
  </script>